import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Text, Stack, SimpleGrid, Button } from '@chakra-ui/react';

import { fetchTasks } from '../redux/slices/tasks';
import { selectIsAuth } from '../redux/slices/auth';

import Card from '../components/Card';
import SkeletonCard from '../components/SkeletonCard';

export default function Home() {
  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => state.tasks);

  const isTaskLoading = tasks.status !== 'loaded'; //для реализации скелетона

  useEffect(() => {
    dispatch(fetchTasks());
  }, []);

  const isAuth = useSelector(selectIsAuth);

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to="/login" />;
  }

  const renderTasks = (items) => {
    if (!Array.isArray(items)) {
      return <p>Произошла ошибка загрузки данных</p>;
    }

    const reversedTasks = [...items].reverse();

    return reversedTasks.map((obj, index) => (
      <Card
        key={index}
        _id={obj._id}
        taskFor={obj.taskFor}
        task={obj.task}
        text={obj.text}
        deadline={obj.deadline}
        success={obj.success}
        attachmentUrl={obj.attachmentUrl}
        taskFrom={obj.taskFrom}
        createdAt={obj.createdAt}
        updatedAt={obj.updatedAt}
      />
    ));
  };

  console.log(tasks);

  return (
    <Stack p="10" spacing="10">
      <Text align="center" as="b" fontSize="4xl">
        Мои задачи
      </Text>
      <Link to="/add-task">
        <Button width="100%" borderRadius="xl" shadow="lg">
          Создать задачу
        </Button>
      </Link>
      <SimpleGrid alignContent="center" minChildWidth="300px" spacing="10">
        {isTaskLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <SkeletonCard key={index} />
            ))
          : renderTasks(tasks.items)}
      </SimpleGrid>
    </Stack>
  );
}

